
import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Button from '../../../components/Button';

import Layout from '../../../components/layout/Layoutexport';



import { Link } from 'gatsby';






const seo={
  title: "Productos de exportacion",
  desc:  "Fabricamos y comercializamos productos para la region y el mundo",
  image: "productos.jpg",
  pathname: "/export/es/productos/",
  alternateEn: "/export/en/products/",
  alternateEs: "/export/es/productos/",
  alternateEsec: "/productos/",
  alternateDefault: "/export/es/productos/"
}

const siteVerOpts = [

  {
    label: 'Internacional',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/productos/',

  },


];

const langOpts = [

  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: '/export/en/products/',

  },


];

const Products = ({location}) => (

          <Layout location={location} crumbLabel="Productos de Exportación"
            siteVerOpts={siteVerOpts} langOpts={langOpts}
            alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

          
<section id="productos" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Productos de Higiene Profesional </h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter">Fabricamos y exportamos productos de calidad internacional </h2>
              </div>
            </section>






<section id="productos" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../../images/productos-de-higiene-profesional-para-exportacion.jpg"
                      className="flex justify-center "
                      alt="Productos de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-2xl text-leftfont-normal text-white pb-6">Fabricamos productos de higiene de la más alta calidad para los mercados y las industrias más exigentes. <br/><br/> Nuestra innovación constante nos ha permitido entrar en mercados de la región.<br/> <br/>Nuestra planta ubicada en Quito-Ecuador, cuenta con todas las certificaciones y reconocimientos requeridos para acceder a los mercados más exigentes, como son BPM, BPA, ISO 9001.</p>
    </div>
  </div>
</div>
</section>



<section id="categorias productos" className=" mt-20">
   <h2 className="text-primary-default  title-font text-center text-5xl font-semibold  ">Oferta de Productos de Exportación </h2>
         

    <div className="relative bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../../../images/categoria-dispensadores.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra amplia línea de dispensadores"
                    placeholder="blurred"
                    layout="constrained"/>
                <div className="flex flex-wrap mx-auto justify-center">
                  <Link to="/export/es/dispensadores/" className="text-3xl font-semibold text-lime-500 text-center leading-relaxed">Dispensadores</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Equipos resistentes y elegantes para dispensar papel higiénico, jabón, gel, toallas de manos, servilletas.</p>
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/export/es/dispensadores/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../../../images/categoria-higiene-de-manos.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos profesionales para higiene de manos"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center">
                  <Link to="/export/es/higiene-de-manos/" className="text-3xl font-semibold text-lime-500 text-center leading-relaxed">Higiene de Manos</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Productos de alta calidad para el lavado y desinfección de manos: gel antibacterial, jabón, alcohol.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                <Link to="/export/es/higiene-de-manos/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-industria-alimenticia.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la industria alimenticia"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/export/es/industria-alimenticia/" className="text-3xl font-semibold text-lime-500 text-center">Industria Alimenticia</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Productos químicos altamente concentrados para la desinfección de superficies.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/export/es/industria-alimenticia/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-desinfeccion-de-superficies.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la desinfección de superficies"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/export/es/desinfeccion-de-superficies/" className="text-3xl font-semibold text-lime-500 text-center">Desinfección de Superficies </Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Productos de alta calidad para la desinfección de superficies.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/export/es/desinfeccion-de-superficies/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-absorbentes.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/export/es/absorbentes/" className="text-3xl font-semibold text-lime-500 text-center">Absorbentes </Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Absorbentes fabricados con materia prima de primera calidad que garantizan absorción, suavidad y resistencia.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/export/es/absorbentes/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

     </div>
    </div>
    <div className="pt-20 ">
        <a href="../../../files/catalogo-exportacion-unilimpio.pdf" target="_blank" rel="noopener">
              <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded transition duration-200 ease-in-out bg-primary-default shadow-md hover:bg-secondary-lighter hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none"  >DESCARGUE NUESTRO CATÁLOGO DE PRODUCTOS </Button></a>
      </div>
  </div>
</section>



<section id="contacto" className="mt-4">
<div className="relative bg-lime-500">
  <div className="h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../../images/call-center-unilimpio.jpg"
                      className=" object-cover"
                      alt="Solicite una cotización con Unilimpio"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-20 ">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-4 text-xl sm:text-4xl md:text-4xl font-bold text-white text-center ">¡SOLICITE UNA COTIZACIÓN AHORA!</h2>
      <p className="mt-6 text-2xl text-left font-normal text-white">Gracias por su interés en Unilimpio S.A. Estaremos gustosos de atenderle para brindarle mayor información acerca de nuestros productos de higiene profesional.</p>
      <div className="mt-8">
      <div className="flex flex-wrap mx-auto justify-center pt-4">
         <Link to="/export/es/contacto/">
          <Button className=" text-primary-lighter text-semibold text-xl  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Contáctese</Button>
         </Link>
       </div>
      </div>
    </div>
  </div>
</div>
</section>
         

          </Layout>

)
export default Products
